import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('../../pages/Home'));

function MainRoutes() {
  return (
    <Suspense fallback={<div>Carregando</div>}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default MainRoutes;
